import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
// import { AiOutlineArrowRight } from "@react-icons/all-files/fa/";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaCheckSquare } from "react-icons/fa";
import { FcApproval } from "react-icons/fc";
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, Button } from "../../ui"
const Josim_form = () => {
    return (
        <Section >
            <Container style={{ color:"#2D0E4A" }} >
                <Container>
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <SubheadSmall>Contact our<br /> Team</SubheadSmall>
                            <Text>Upon receiving your message, we will contact you within 24 hours.	</Text>
                        </Box>
                        <Box style={{
                            background: "#FFFFFF",
                            border: "1px solid #F4F4F6",
                            boxSizing: "border-box",
                            color:"#0F0E31",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            borderradius: "16px"
                        }}>
                            <Flex gap={4} variant="responsive" style={{background: "#F4F4F6",borderRadius: "16px 16px 0px 0px"}}>
                                <Box>
                                    <StaticImage src="../images/email_box.png" alt="email_box" />
                                </Box>
                                <Box>
                                    <Text>We’re happy to answer questions and get you acquainted with Skill-Elevator.</Text>
                                </Box>
                            </Flex>
                            <Flex gap={4} variant="responsive">
                                <Box>
                                <input type="text" placeholder='FirstName'/>
                                </Box>
                                <Box>
                                <input type="text" placeholder='LastName'/>
                                </Box>
                                
                            </Flex>
                            <Flex gap={4} variant="responsive">
                                <Box>
                                <input type="text" placeholder='Email'/>
                                </Box>
                                <Box>
                                <input type="text" placeholder='PhoneNumber'/>
                                </Box>  
                            </Flex>
                            <Flex gap={4} variant="responsive">
                                <Box>
                                    <SubheadSmall>Optional</SubheadSmall>
                                <input type="text" placeholder='Message'/>
                                </Box>  
                            </Flex>

                            <Button>Sumit</Button>
                        </Box>
                    </Flex>
                </Container>


            </Container>
        </Section>
    )
}
export default Josim_form;