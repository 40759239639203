import * as React from "react"
import { graphql } from "gatsby"
import { Container, Box, Text, Section, Flex, Heading } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import king_icon from '../../../images/king_icon.png'

export default function Josim_about(props) {
    return (
        <Section style={{ color: "#2D0E4A" }} >
            {/* <Container width="tight" style={{ color: "#2D0E4A" }} > */}

                <Flex >
                    <Box>
                        <Heading>
                            <StaticImage src='../../images/Skill_elevator_icon.png' alt="Skill_elevator_icon" width={30} />About Bizlab
                        </Heading>
                        <Text>
                            Business Laboratory Provides virtual Experience of various Accounting and Tax works.
                        </Text>

                    </Box>
                </Flex>
                <Flex gap={4} variant="responsive">
                    <Box>
                        <Text>It is a web platform consists of various working modules created for various job roles.
                             It has five job roles such as (a) Accounts Officer (b) BPO Specialist © Tax Accountant 
                             (d) FP&A (e) Compliance Officer Each role is created based on what exactly one person does 
                             any work once he/she get job. It is conolidation of all possible works that one can do in the
                              Industry. Before starting this module, student has to complete the Business Laboratory which
                               gives different set of work experience as a first step. Using all the modules or transactions 
                               process, one can easily understand any job role. After completion of each job student will get
                                a certificate like as an example after completing Accounts Officer Training, he/she gets "Practial 
                                On-Job Training Completion Certificate for Accounts Officer" After completion of all 5 Job Training he
                              will be awarded a qualification as " ProFinance Manager" (Equal to 3 years of experianced person in the Industry) 


                        </Text>
                        <Flex gap={4} variant="responsive">
                        <Box>
                        <StaticImage src='../../../images/work_virtual_company.png' alt="work_virtual_company" />
                            <Text>20 in-depth working modules</Text>
                        </Box>
                        <Box>
                          <StaticImage src='../../../images/king_icon.png' alt="king_icon" />
                        <Text>Access to 1 virtual company to work</Text>
                        </Box>
                        <Box>
                          <StaticImage src='../../../images/verified_cv.png' alt="verified" />
                        <Text>Experience certificate with Pro-Accountant Qualification</Text>
                        </Box>
                        </Flex>
                    </Box>
                    <Box>
                          <StaticImage src='../../../images/Josim_images/josim_img2.png' alt="josim_img2" />
                    </Box>
                </Flex>
                <Container>
                <Flex>
                <Box gap={4} variant="responsive">
                          <StaticImage src='../../../images/Josim_images/josim_img3.png' alt="josim_img3" />
                    </Box>
                </Flex>
                </Container>
               
            {/* </Container> */}


        </Section>
    )
}
