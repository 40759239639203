import * as React from "react"
import { graphql } from "gatsby"
import { Container, Box, Text, Section, Flex, Heading } from "../../ui"
import { StaticImage } from "gatsby-plugin-image"
import king_icon from '../../../images/king_icon.png'

export default function Josim_Get_started(props) {
    return (
        <Section>
            <Container width="tight" style={{ color: "#2D0E4A" }} >

                <Flex gap={4} variant="responsive">
                    <Box>
                        <Heading>
                            Get Started with<StaticImage src='../../images/Skill_elevator_icon.png' alt="Skill_elevator_icon" width={30} /><br />
                            Job Simulator
                        </Heading>
                        <StaticImage src='../../../images/Josim_images/josim_img3.png' alt="josim_img3" />
                    </Box>
                </Flex>



            </Container>


        </Section>
    )
}
