import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PlatformNavList from '../components/platform/nav-items';
import PlatformHeaderTiles from "../components/platform/platform_header";
import Josim_about from "../components/platform/josim_page/josim_about";
import Working_module from "../components/platform/josim_page/working_module";
import Josim_Get_started from "../components/platform/josim_page/josim_getstated";
import Josim_faq from "../components/platform/josim_page/josim_faq";
import Josim_form from "../components/platform/josim_page/josim_form";


const JosimPage = () => (
  <Layout>
    <Seo title="Josim" />
    <PlatformNavList navName="Josim"/>
    <PlatformHeaderTiles header="Job Simulator" imageName="job_simulator"/>
    <Josim_about/>
    <Working_module/>
    <Josim_Get_started/>
    <Josim_faq/>
    <Josim_form/>
   
  </Layout>
)

export default JosimPage
